import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import LanguageTranslations from '../language_mappings.json'
import { DynamoDBClient } from '@aws-sdk/client-dynamodb'
import { TranslateClient } from '@aws-sdk/client-translate'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

import API from '../api/api'
import { useAuth0 } from '@auth0/auth0-react'

export const globalContext = React.createContext(null)

const defaultLanguage =
  typeof window !== 'undefined' && localStorage.getItem('language')

export const GlobalContext = props => {
  let defaultLang = ''
  if (defaultLanguage === null || defaultLanguage === '') {
    defaultLang = 'en'
  } else {
    defaultLang = defaultLanguage
  }
  const [language, setLanguage] = useState(defaultLang)
  const [languageDictionary, setLanguageDictionary] = useState('')
  const [uData, setUData] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { isAuthenticated, user, isLoading } = useAuth0()
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` })
  const [nickname, setNickname] = useState(null)

  // let nickname = null

  //Provide database to context
  const db = new DynamoDBClient({
    region: 'us-east-1',
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: 'us-east-1' },
      identityPoolId: 'us-east-1:1a9a8ad2-cf14-4779-878a-fb869c818fea',
      //customRoleArn: "arn:aws:iam::783364685273:role/service-role/Cognito_AdminPortalUnauth",
    }),
  })

  const translator = new TranslateClient({
    region: 'us-east-1',
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: 'us-east-1' },
      identityPoolId: 'us-east-1:1a9a8ad2-cf14-4779-878a-fb869c818fea',
      //customRoleArn: "arn:aws:iam::783364685273:role/service-role/Cognito_AdminPortalUnauth",
    }),
  })

  //Provide user data to global context
  useEffect(() => {
    // This function fetches all user data from the Heroku server
    const fetchData = async () => {
      setLoading(true)
      //If authenticated, and Auth0 is not loading still, run this
      if (isAuthenticated && !isLoading) {
        API.getSalesforceDataLinks(user.email)
          .then(response => {
            API.getServicesFeedback(response.result.AccountId)
              .then(response2 => {
                setNickname(response.result.Account.Name)
                setLoading(false)
                setUData({
                  // Account ID (organization ID) of the user from REQUEST 1
                  organizationName: response.result.Account.Name,
                  accountID: response.result.AccountId,
                  assigned_keyword: response.result.Account.Assigned_Keyword__c,
                  org_stage: response.result.Account.Stage_Currently_In__c,
                  dataLinks: {
                    // Tableau Dashboard link from REQUEST 1
                    dashboard_link: response.result.Tableau_Dashboard_Link__c,
                    // Checkins Report link from REQUEST 1
                    report_link: response.result.Checkins_Data_Report_Link__c,
                    // Live Checkin link from REQUEST 1
                    live_checkin_link: response.result.Live_Checkins_Link__c,
                    // SMS Status Report Link from REQUEST 1
                    sms_status_report_link:
                      response.result.SMS_Status_Report_Link__c,
                    // Flyer Link from REQUEST 1
                    flyer_link: response.result.Access_Flyer__c,
                    // Checkins modification form Link from REQUEST 1
                    checkins_mod_link:
                      response.result.Checkins_Modification_Form_Link__c,
                    // Add New User Form link from REQUEST 1
                    add_new_user_link:
                      response.result.Checkins_Add_User_Form_Link__c,
                    //Account type- Free/Trialing/Paying
                    account_type: response.result.Stage_6_Status__c,
                  },
                  // Access role of the user from REQUEST 1
                  accessRole: response.result.Checkins_Data_Access_Role__c,
                  // Services Feedback of the user's organization from REQUEST 2
                  challenges_experienced:
                    response.result.Account.Challenges_Experienced__c,
                  servicesFeedback: response2.data,
                  servicesFeedbackAgg: {
                    // Feedback Aggregate Information
                    Feedback_Last_7_Days__c:
                      response.result.Feedback_Last_7_Days__c,
                    Feedback_Last_30_Days__c:
                      response.result.Feedback_Last_30_Days__c,
                    Total_Feedback__c: response.result.Total_Feedback__c,
                    Last_7_Days_Rating_Avg__c:
                      response.result.Last_7_Days_Rating_Avg__c,
                    Last_30_Days_Rating_Avg__c:
                      response.result.Last_30_Days_Rating_Avg__c,
                    Avg_Rating_All_Time__c:
                      response.result.Avg_Rating_All_Time__c,
                  },
                  // Updated time of feedback of the user's organization from REQUEST 2
                  updatedFeedbackAt: response2.UpdatedAt,
                  // Refresh frequency (in minutes) of feedback of the user's organization from REQUEST 2
                  refreshFrequency: response2.refreshCadence,
                  
                })
              })
              .catch(error => {
                const errorMessage = JSON.stringify(error)
                setError(errorMessage)
                setLoading(false)
              })
          })
          .catch(error => {
            const errorMessage = JSON.stringify(error)
            setError(errorMessage)
            setLoading(false)
          })
      }
    }
    fetchData()
  }, [isAuthenticated, user, setLoading, isLoading])

  useEffect(() => {
    localStorage.setItem('language', language)
    setLanguageDictionary(LanguageTranslations[language])
  }, [language])


  return (
    <globalContext.Provider
      value={{
        isAuthenticated,
        loading,
        user,
        error,
        language,
        changeLanguage: value => {
          setLanguage(value)
        },
        languageDictionary,
        db,
        translator,
        nickname,
        uData,
        updateUData: value => {
          setUData(value)
        },
        isError,
        isMobile,
      }}
    >
      {props.children}
    </globalContext.Provider>
  )
}

export default ({ children }) => <GlobalContext>{children}</GlobalContext>
