import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

import { makeStyles } from '@mui/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';

import insightsData from '../../card_data.json';
import { globalContext } from '../../context/GlobalContext';
import LockAlertDialog from '../lockAlertDialog';

const useStyles = makeStyles(theme => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'black',
  },

  menuItem: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'Montserrat',
  },
  menuItemText: {
    fontFamily: 'Montserrat',
  },
  disabledMenuItem: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'Montserrat',
    opacity: 0.3,
  },
  sectionHeader: {
    paddingLeft: '10px',
    marginBottom: '0px',
    color: '#000000',
    opacity: 0.5,
    fontFamily: 'Montserrat',
  },
}));

const list = (setSection, setPopupOpen, context, item, classes) => {
  return (
    <>
      {item.lock === 'false' ? (
        <ListItem
          key={item.title}
          button
          component={Link}
          to={item.destination}
          className={classes.menuItem}
        >
          <ListItemIcon style={{ paddingLeft: '0.3rem' }}>
            <img
              src={item.image}
              width='20'
              height='20'
              style={{ marginBottom: 0 }}
              alt={item.title}
            />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>
            {' '}
            {context.languageDictionary[item.title]}
          </Typography>
        </ListItem>
      ) : (
        <>
          <ListItem
            key={item.title}
            button
            onClick={() => {
              setPopupOpen(true);
              setSection(context.languageDictionary[item.title]);
            }}
            className={classes.disabledMenuItem}
          >
            <ListItemIcon style={{ paddingLeft: '0.3rem' }}>
              <img
                src={item.image}
                width='20'
                height='20'
                style={{ marginBottom: 0 }}
                alt={item.title}
              />
            </ListItemIcon>
            <Typography className={classes.menuItemText}>
              {' '}
              {context.languageDictionary[item.title]}
            </Typography>
          </ListItem>
        </>
      )}
    </>
  );
};

const HamBurgerMenu = () => {
  const classes = useStyles();
  const context = useContext(globalContext);
  const { logout } = useAuth0();
  const [section, setSection] = React.useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [popupOpen, setPopupOpen] = React.useState(false);

  if (
    context.uData &&
    context.uData.challenges_experienced ===
      '2. Collecting ongoing feedback on the experience and outcomes from your clientele.'
  ) {
    for (var key in insightsData) {
      if (insightsData.hasOwnProperty(key)) {
        if (insightsData[key].title === 'hope_one_source_me') {
          insightsData[key].lock = 'false';
        } else {
          insightsData[key].lock = 'true';
        }
      }
    }
  }

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <React.Fragment key={'right'}>
        {/* The hamburger icon */}
        <IconButton
          edge='start'
          className={classes.menuButton}
          aria-label='menu'
          onClick={toggleDrawer('right', true)}
          size='large'
        >
          <MenuIcon />
        </IconButton>

        {/* The hamburger menu */}
        <SwipeableDrawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          <div
            className={classes.list}
            role='presentation'
            onClick={toggleDrawer('right', false)}
            onKeyDown={toggleDrawer('right', false)}
          >
            {/* Home */}
            <List>
              <ListItem button component={Link} to={'/account'}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <Typography className={classes.menuItemText}>
                  {' '}
                  {context.languageDictionary.home}
                </Typography>
              </ListItem>
            </List>

            {/* Outreach */}
            {/* <Divider />
            <Typography
              gutterBottom
              variant='body1'
              className={classes.sectionHeader}
            >
              Outreach
            </Typography>
            <List>
              {list(
                setSection,
                setPopupOpen,
                context,
                insightsData['schedule_outreach'],
                classes
              )}
              {insightsData['flyer'].link &&
                list(
                  setSection,
                  setPopupOpen,
                  context,
                  insightsData['flyer'],
                  classes
                )}
            </List> */}

            {/* Custom Check-Ins */}
            <Divider />
            <Typography
              gutterBottom
              variant='body1'
              className={classes.sectionHeader}
            >
              Custom Check-Ins
            </Typography>
            <List>
              {insightsData['check_in_modification'].link &&
                list(
                  setSection,
                  setPopupOpen,
                  context,
                  insightsData['check_in_modification'],
                  classes
                )}
              {insightsData['live_checkin'].link &&
                list(
                  setSection,
                  setPopupOpen,
                  context,
                  insightsData['live_checkin'],
                  classes
                )}
              {/* {list(
                setSection,
                setPopupOpen,
                context,
                insightsData['sms_status_report'],
                classes
              )}
              {list(
                setSection,
                setPopupOpen,
                context,
                insightsData['hope_one_source_me'],
                classes
              )} */}
            </List>

            {/* Settings */}
            <Divider />
            <Typography
              gutterBottom
              variant='body1'
              className={classes.sectionHeader}
            >
              Settings
            </Typography>
            <List>
              {insightsData['add_new_user'].link &&
                list(
                  setSection,
                  setPopupOpen,
                  context,
                  insightsData['add_new_user'],
                  classes
                )}
            </List>

            {/* Log Out */}
            <Divider />
            <List>
              <ListItem
                onClick={e => {
                  logout({ returnTo: window.location.origin });
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <Typography className={classes.menuItemText}>
                  {' '}
                  {context.languageDictionary.log_out}
                </Typography>
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
      {popupOpen && (
        <LockAlertDialog
          open={popupOpen}
          context={context}
          section={section}
          onClose={() => setPopupOpen(false)}
        />
      )}
    </div>
  );
};

export default HamBurgerMenu;
