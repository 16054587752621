import React from 'react'
import { Link } from 'gatsby'
import { globalContext } from '../../context/GlobalContext'
import { useAuth0 } from '@auth0/auth0-react'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import { styled } from '@mui/system'

import NotificationMenu from '../notificationMenu'
import HamBurgerMenu from './hamBurgerMenu'
import mobileLogo from '../../images/HOS_Logo_Condensed.png'
import desktopLogo from '../../images/HOSLogo.png'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  height: '89px',
  width: '100%',
}))

const Logo = styled('img')(({ theme }) => ({
  width: '200px',
  marginTop: '1.5rem',
}))

const LogoMobile = styled('img')(({ theme }) => ({
  width: '60px',
  marginTop: '1rem',
  maxWidth: '60px',
  maxHeight: '60px',
}))

const GiveFeedbackButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  marginRight: '1rem',
  borderColor: '#C1272D',
  color: '#C1272D',
}))

const GiveFeedbackButtonMobile = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  marginTop: '2rem',
  borderColor: '#C1272D',
  color: '#C1272D',
}))

const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  color: 'black',
}))

const SupportButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  marginRight: '1rem',
  backgroundColor: '#C1272D',
  color: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: '#C1272D',
  },
}))

const Header = () => {
  const context = React.useContext(globalContext)
  const { isAuthenticated, logout } = useAuth0()

  return (
    <StyledAppBar position='fixed'>
      <Toolbar style={{ justifyContent: 'space-between', display: 'flex' }}>
        {/* Logo Section */}
        <Link to='/account'>
          <Hidden only={['md', 'lg', 'xl']}>
            <LogoMobile src={mobileLogo} alt='HOS Logo' />
          </Hidden>
          <Hidden only={['xs', 'sm']}>
            <Logo src={desktopLogo} alt='HopeOneSource Logo' />
          </Hidden>
        </Link>

        {/* Right-aligned items */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Hidden only={['md', 'lg', 'xl']}>
            {/* Mobile items here, adjust as needed */}
            {isAuthenticated && (
              <>
                <GiveFeedbackButtonMobile
                  variant='outlined'
                  component={Link}
                  to='/giveFeedback'
                >
                  {context.languageDictionary.give_feedback}
                </GiveFeedbackButtonMobile>
                {context.uData && <NotificationMenu />}
                {context.uData && <HamBurgerMenu />}
              </>
            )}
          </Hidden>
          <Hidden only={['xs', 'sm']}>
            {/* Desktop items here */}
            {context.uData && <NotificationMenu />}
            <GiveFeedbackButton
              variant='outlined'
              component={Link}
              to='/giveFeedback'
            >
              {context.languageDictionary.give_feedback}
            </GiveFeedbackButton>
            <SupportButton
              variant='contained'
              href='https://www.hope1source.org/support-ticket'
              target='_blank'
            >
              {context.languageDictionary.support}
            </SupportButton>
            <SupportButton
              variant='contained'
              onClick={e => {
                logout({ returnTo: window.location.origin })
              }}
            >
              {context.languageDictionary.log_out}
            </SupportButton>
            {context.uData && <HamBurgerMenu />}
          </Hidden>
        </div>
      </Toolbar>
    </StyledAppBar>
  )
}

export default Header
