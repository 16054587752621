const axios = require("axios")
var API = axios.create({
  baseURL: process.env.GATSBY_SERVER_BASE_URL,
})

// Add a response interceptor for better error messages
API.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // If we have an error message from the server, return that as the error
    if (error.response && error.response.data && error.response.data.message) {
      // context.updateAPIError(error.response.data.message)
      return Promise.reject(error.response.data.message)
    } else {
      // context.updateAPIError(error)
      return Promise.reject(error)
    }
  }
)

const functions = {
  async getAccountById(id) {
    let response = await API.get(`v1/account?id=${id}`)
    return response.data
  },
  async getSalesforceDataLinks(email) {
    let response = await API.get("v1/portalLogin", { params: { email } })
    return response.data
  },
  async getServicesFeedback(accountId) {
    let response = await API.get("v1/servicesFeedback", {
      params: { accountId },
    })
    return response.data
  },
  async getOutreachReport(accountId) {
    let response = await API.get("v1/outreach", {
      params: { accountId },
    })
    return response.data
  },
  async getOutreachMessages(
    start_date,
    end_date,
    direction,
    bulkMessageId,
    smsKeyword,
    status
  ) {
    let response = await API.get("v1/messages", {
      params: {
        start_date,
        end_date,
        direction,
        bulkMessageId,
        smsKeyword,
        status,
      },
    })
    return response.data
  },
  async getFavorites(accountId) {
    let response = await API.get("v1/account/swipes", {
      params: { accountId },
    })
    return response.data
  },
  async getAllVerifiedServiceProviders() {
    let response = await API.get("v1/accounts")
    return response.data
  },
  async getAllProviders() {
    let response = await API.get("v1/all-accounts")
    return response.data
  },
}

export default functions
