import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Typography from "@mui/material/Typography"
import { globalContext } from "../context/GlobalContext"

// Add language abbreviation along with full name here
const languageName = {
  en: "English",
  ja: "日本語",
  es: "Español",
}

export default function LanguageSelector() {
  const context = React.useContext(globalContext)
  const [language, setLanguage] = React.useState(context.language)

  const handleChange = (event) => {
    setLanguage(event.target.value)
    context.changeLanguage(event.target.value)
  }

  return (
    <Box>
      <FormControl variant="standard" sx={{ minWidth: "150px" }}>
        <InputLabel id="language-select-label">{context.languageDictionary.language}</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={language}
          label={context.languageDictionary.language}
          onChange={handleChange}
          sx={{ fontFamily: "Montserrat" }}
        >
          {Object.keys(languageName).map(abbreviation =>
            <MenuItem key={abbreviation} value={abbreviation}>
              <Typography sx={{ fontFamily: "Montserrat" }}> {languageName[abbreviation]}</Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  )
}
