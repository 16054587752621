import React from "react";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    // mode:'dark',
    primary: {
      light: "rgb(251, 176, 59)",
      main: "#c1272d",
      dark: "rgb(50, 63, 75)",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h3: {
      fontWeight: 700,
      fontSize: "1.5rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
      "@media (min-width:960px)": {
        fontSize: "2.5rem",
      },
    },
    h6: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.2rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.9rem",
      },
      "@media (min-width:1160px)": {
        fontSize: "0.9rem",
      },
      "@media (min-width:1275px)": {
        fontSize: "1.1rem",
      },
    },
    body1: {
      fontSize: "0.7rem",
      "@media (min-width:600px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width:960px)": {
        fontSize: "1.05rem",
      },
    },
    body2: {
      fontSize: "0.7rem",
      "@media (min-width:600px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.875rem",
      },
    },
    button: {
      fontWeight: 700,
      fontSize: "0.85rem",
      "@media (min-width:600px)": {
        fontSize: "0.75rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.75rem",
      },
      "@media (min-width:1175px)": {
        fontSize: "0.85rem",
      },
    },
  },
});

export default function MyThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
