import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthWrapper from './src/context/AuthWrapper';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalContext from './src/context/GlobalContext';
import MaterialUITheme from './src/theme';
import Layout from './src/components/Layout/layout';
import { navigate } from 'gatsby';

const onRedirectCallback = appState => {
  navigate(appState?.returnTo || '/');
};

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: process.env.GATSBY_AUTH0_CALLBACK,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      responseType='token id_token'
      scope='openid profile mail'
    >
      <Router>
        <GlobalContext>
          <MaterialUITheme>
            <AuthWrapper>{element}</AuthWrapper>
          </MaterialUITheme>
        </GlobalContext>
      </Router>
    </Auth0Provider>
  );
};

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
