import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Header from "./header";
import Footer from "./footer";
import Box from '@mui/material/Box';
import "./layout.css";
import MyThemeProvider from "../../theme";
const BodyLayout = styled(Box)(({ theme }) => ({
  margin: '5rem auto',
  padding: '1rem',
  width: {
    xs: '90%',
    sm: '80%',
    md: '70%',
    lg: '60%',
  },
  maxWidth: '1280px',
  // backgroundColor: theme.palette.primary.dark,
}));

const Layout = props => {
  const { children, uri } = props;

  if (uri === "/embeddedCheckin") {
    return <>{children}</>;
  }

  return (
    <MyThemeProvider>
      <Header />
      <BodyLayout>
        <main>{children}</main>
      </BodyLayout>
      <Footer />
    </MyThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
