import React, { useContext } from 'react'
import { globalContext } from '../../context/GlobalContext'
import { useAuth0 } from '@auth0/auth0-react'
import { Typography, Button, Grid, Hidden, Box } from '@mui/material'
import LanguageSelector from '../languageSelector'

export default function Footer(props) {
  const context = useContext(globalContext)
  const { isAuthenticated } = useAuth0()

  return (
    <Box
      component='footer'
      sx={{
        backgroundColor: '#f5f5f5',
        marginTop: '1rem',
        left: 0,
        bottom: 0,
        right: 0,
        textAlign: 'center',
        // Adjust padding here. Consider reducing vertical padding.
        padding: '20px 30px', // Reduced vertical padding from "30px" to "20px"
      }}
    >
      <Grid container spacing={2}>
        {' '}
        {/* Reduced spacing from 3 to 2 */}
        {isAuthenticated && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='contained'
              sx={{
                fontFamily: 'Montserrat',
                margin: '0.5rem', // Reduced margin
                backgroundColor: '#C1272D',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#C1272D',
                },
              }}
              target='_blank'
              href='https://www.hope1source.org/data-insights-portal-documentation'
            >
              DOCUMENTATION
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <LanguageSelector />
        </Grid>
      </Grid>
      {/* Removed <br /> tags */}
      <Typography
        variant='h5' // Use responsive font sizes
        sx={{ fontFamily: 'Montserrat', mt: 2 }} // Add margin-top to create space between text and other elements if needed
      >
        {context.languageDictionary.mission}{' '}
        <Box component='b' sx={{ color: '#C1272D' }}>
          {context.languageDictionary.mission2}
        </Box>{' '}
        {context.languageDictionary.mission3}{' '}
        <Box component='i'>{context.languageDictionary.mission4}</Box>
      </Typography>
    </Box>
  )
}
