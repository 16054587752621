import React, { useEffect, useContext, useState } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { globalContext } from "../context/GlobalContext"

import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import NotificationsIcon from "@mui/icons-material/Notifications"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import Badge from "@mui/material/Badge"
import Fade from "@mui/material/Fade"
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Hidden from "@mui/material/Hidden"

import Select from "@mui/material/Select"
import { ViewArray } from "@mui/icons-material"

const menuCard = (handleClose, readNotification, newNotificationCount) => {
  return (
    <MenuItem
      disabled={readNotification || newNotificationCount === 0 ? true : false}
      onClick={handleClose}
    >
      {readNotification || newNotificationCount === 0 ? (
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <Typography
            style={{ fontSize: 16, color: "black" }}
            variant="body2"
            component="p"
          >
            You're up-to-date &#x1F389;
          </Typography>
          <Typography
            style={{ fontSize: 16, color: "black" }}
            variant="body2"
            component="p"
          >
            No new notifications!
          </Typography>
        </div>
      ) : (
        // </MenuItem>
        // <MenuItem onClick={handleClose}>
        <Card style={{ border: "0.5px solid black", borderRadius: 8 }}>
          <CardActionArea component={Link} to={"/account/h1sme"}>
            <CardContent>
              <Typography
                style={{ fontSize: 14 }}
                color="textSecondary"
                variant="body2"
                gutterBottom
              >
                Feedback
              </Typography>
              <Typography
                style={{ fontSize: 16 }}
                variant="body2"
                component="p"
              >
                You have received {newNotificationCount} new feedback!
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        // </MenuItem>
      )}
    </MenuItem>
  )
}

const NotificationMenu = () => {
  const context = useContext(globalContext)
  const { user } = useAuth0()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [newNotificationCount, setNewNotificationCount] = useState(0)
  const [readNotification, setReadNotification] = useState(false)

  // var lastUpdatedDate = user && new Date(Date.parse(user.updated_at))
  var lastLoginDate = user && new Date(Date.parse(user.updated_at))
  // const strDate = "2022-01-20";
  // const lastLoginDate = new Date(strDate);
  // console.log("last updated date: "+lastLoginDate)
  // console.log("last login date: "+lastLoginDate)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setReadNotification(true)
    // Set Notication Count to 0
    setNewNotificationCount(0)
  }

  useEffect(() => {
    const checkNewNotifications = async () => {
      if (context.uData) {
        var newArray =
          context.uData.servicesFeedback &&
          context.uData.servicesFeedback.filter(function (el) {
            // var count = 1
            var feedbackDate = new Date(Date.parse(el.CreatedDate))
            // count+=1
            return feedbackDate > lastLoginDate
          })
        // const maxDate = (...dates) => new Date(Math.max(...dates));

        // console.log(newArray)
        // var maxDate = new Date(Math.max(...newArray.map(e => new Date(e.CreatedDate))));;
        //   console.log("latest feedback date: "+maxDate)

        if (newArray && newArray.length > 0) {
          setNewNotificationCount(newArray.length)
        } else {
        }
      }
    }
    checkNewNotifications()
  }, [])

  const menuProps = {
    disableScrollLock: false,
    padding: 0,
  }

  return (
    <div>
      {/* Mobile Display */}
      <Hidden only={["md", "lg", "xl"]}>
        <IconButton
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          onClick={handleClick}
          style={{ padding: 0 }}
          size="large"
        >
          {readNotification || newNotificationCount === 0 ? (
            <NotificationsNoneIcon />
          ) : (
            <Badge badgeContent={newNotificationCount} color="primary">
              <NotificationsIcon />
            </Badge>
          )}
        </IconButton>
      </Hidden>
      {/* Desktop Display */}
      <Hidden only={["xs", "sm"]}>
        <IconButton
          id="notif-button"
          aria-controls={open ? "notif-menu" : undefined}
          onClick={handleClick}
          style={{ marginRight: "1rem" }}
          size="large"
        >
          {readNotification || newNotificationCount === 0 ? (
            <NotificationsNoneIcon />
          ) : (
            <Badge badgeContent={newNotificationCount} color="primary">
              <NotificationsIcon />
            </Badge>
          )}
        </IconButton>
      </Hidden>

      <Menu
        id="notif-menu"
        anchorEl={anchorEl}
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 8 } }}
        // anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Fade}
      >
        {menuCard(handleClose, readNotification, newNotificationCount)}
      </Menu>
    </div>
  )
}

export default NotificationMenu
