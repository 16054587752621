import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { LinearProgress } from '@mui/material';

const AuthWrapper = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    const handleNavigation = async () => {
      const isLoginPage = window.location.pathname === '/';

      if (!isLoading) {
        if (!isAuthenticated && !isLoginPage) {
          console.log('User not authenticated, redirecting to login page');
          setIsNavigating(true);
          await navigate('/');
          setIsNavigating(false);
        } else if (isAuthenticated && isLoginPage && user) {
          console.log('User authenticated, redirecting to account page');
          setIsNavigating(true);
          await navigate('/account');
          setIsNavigating(false);
        }
      }
    };

    handleNavigation();
  }, [isAuthenticated, isLoading, user]);

  if (isLoading || isNavigating) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LinearProgress style={{ width: '50%' }} />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
