import React from "react"
import { Link } from "gatsby"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  giveFeedbackButton: {
    // fontFamily: "Montserrat",
    marginRight: "1rem",
    borderColor: "#C1272D",
    color: "#C1272D",
  },

  supportButton: {
    // fontFamily: "Montserrat",
    marginRight: "1rem",
    backgroundColor: "#C1272D",
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "#C1272D",
    },
  },
}))

const ButtonMailto = ({ mailto, label }) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={classes.supportButton}
      component={Link}
      to="#"
      target="_blank"
      autoFocus
      onClick={e => {
        if (typeof window !== "undefined") {
          window.open(mailto)
        }
        e.preventDefault()
      }}
    >
      {label}
    </Button>
  )
}

export default ButtonMailto
