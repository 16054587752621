import React from "react"

import { makeStyles } from "@mui/styles"
import Dialog from "@mui/material/Dialog"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Grid from "@mui/material/Grid"

import ButtonMailto from "./VerifiedServices/buttonMailTo"

const useStyles = makeStyles({
  message: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    paddingLeft: "20px",
  },
  contactCount: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#C1272D",
  },
  closeButton: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    marginBottom: "1rem",
    color: "#C1272D",
  },
})

const LockAlertDialog = props => {
  const classes = useStyles()

  // console.log(props)

  function handleClose() {
    props.onClose()
  }

  var mailToHref =
    props.section &&
    `mailto:portal@hope1source.me?subject=Data and Insights Portal: "${props.section}" Support Requested &body=Hi,%0D%0A%0D%0AI'd love to learn more or request access to "${props.section}" in the Data and Insights Portal`

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={() => handleClose()}
        fullWidth
      >
        <div style={{ padding: "1rem" }}>
          <DialogTitle
            style={{ fontFamily: "Montserrat", padding: "0" }}
            id="alert-dialog-title"
          >
            <Grid container>
              <Grid item xs={11}>
                Need access to {props.section}?
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "10px",
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContentText id="alert-dialog-description">
            If you'd like to learn more or request access, click below or email
            portal@hope1source.me
          </DialogContentText>
          <DialogActions>
            <ButtonMailto label="Request Access" mailto={mailToHref} />
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}

export default LockAlertDialog
